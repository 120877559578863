import React, { FC } from 'react'

import { InfoOutlined, LocalGasStation, LocalShipping, PlaylistAddCheck, Store } from '@mui/icons-material'

import TooltipIcon from '../../../../ui/ToolTipIcon/ToolTipIcon'

import styles from './styles.module.scss'


type TooltipIconsProps = {
    nominatedBarges: boolean
    tanks: boolean
    openHopper: boolean
    havingTboInfo: boolean
    placedToLoad: boolean
}

const TooltipIcons: FC<TooltipIconsProps> = ({ nominatedBarges, tanks, openHopper, havingTboInfo, placedToLoad }) => {
    const renderTooltipMessage = (isActive: boolean, name: string) => {
        return `${name}: ${isActive ? 'Active' : 'Inactive'}`
    }

    return (
        <div className={styles.iconContainer}>
            <TooltipIcon
                isActive={nominatedBarges}
                tooltip={renderTooltipMessage(nominatedBarges, 'Nominated Barges')}
                icon={<LocalShipping />}
            />
            <TooltipIcon isActive={tanks} tooltip={renderTooltipMessage(tanks, 'Tanks')} icon={<LocalGasStation />} />
            <TooltipIcon isActive={openHopper} tooltip={renderTooltipMessage(openHopper, 'Open Hopper')} icon={<Store />} />
            <TooltipIcon
                isActive={havingTboInfo}
                tooltip={renderTooltipMessage(havingTboInfo, 'Having TBO Info')}
                icon={<InfoOutlined />}
            />
            <TooltipIcon
                isActive={placedToLoad}
                tooltip={renderTooltipMessage(placedToLoad, 'Placed to Load')}
                icon={<PlaylistAddCheck />}
            />
        </div>
    )
}

export default TooltipIcons
