import { GraphqlBargeType as BargeType } from '../generated/graphql'

export enum ToggleExcludeKeys {
  NOMINATED_BARGES = 'nominatedBarges',
  TANKS = 'tanks',
  OPEN_HOPPER = 'openHopper',
  HAVING_TBO_INFO = 'havingTboInfo',
  PLACED_TO_LOAD = 'placedToLoad',
}

const excludeBargeTankTypes = [BargeType.TankCaustic, BargeType.TankChemical, BargeType.TankFuel]
const excludeOpenHopperTypes = [BargeType.HoppOpen]

export const getExcludedBargeTypes = (excludeTanks: boolean, excludeHoppers: boolean) => [
  ...(excludeTanks ? excludeBargeTankTypes : []),
  ...(excludeHoppers ? excludeOpenHopperTypes : []),
]

export const isExcludingTanks = (bargeTypes: BargeType[]) => excludeBargeTankTypes.every(t => bargeTypes.includes(t))
export const isExcludingHoppers = (bargeTypes: BargeType[]) => excludeOpenHopperTypes.every(t => bargeTypes.includes(t))
