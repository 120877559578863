import { ELLIPSIS } from '../constants/constants'
import {
  BargeNominationFilters,
  BargeNominationRequest,
  Fleet,
  GoalId,
  GraphqlBargeType,
  HullType,
  LoadStatus,
  NominatedTbnBarge,
  PickupType,
  RiskLevel,
  RiverLocationLite,
  TowConfiguration,
  TripStatusId,
} from '../generated/graphql'
import { durationComponentsFromMinutes, getTimeFromExpectedDeparture } from '../utils/date'

import { parseDate } from './Barge'
import { Filter } from './BargeFilters'
import { isExcludingHoppers, isExcludingTanks } from './BargeType'
import { FacilityLocation } from './Facility'
import { isGoalId } from './Goal'
import { equals } from './River'
import { isExcludingPlacedToLoad } from './Trip'

export type TowNominationInput = {
  goal: GoalId
  numberOfLoaded: number
  numberOfEmpties: number
  numberOfStrings: number | null
  preselectedBarges: string[]
  boat?: string
  hasTurnboat: boolean
}

export type NominationInput = {
  prioritizeHotBarges: boolean
  towConfiguration: TowNominationInput
}

export const isTowNominationInput = (config: any): config is TowNominationInput => isGoalId(config.goal)

export const fromTowNominationInput = (input: TowNominationInput): TowConfiguration => {
  const { goal, numberOfLoaded, numberOfEmpties, preselectedBarges, numberOfStrings, boat, hasTurnboat } = input

  const numberOfBarges = numberOfLoaded + numberOfEmpties

  return {
    goal: goal as GoalId,
    numberOfBarges,
    numberOfEmptyBarges: numberOfEmpties ?? 0,
    preselectedBarges,
    boat: boat ?? null,
    numberOfStrings,
    hasTurnboat: hasTurnboat ?? false,
  }
}

export type NominatableBoat = {
  name: string | null
  boatId: any
  abbreviation: string | null
}

export type NominatedBarge = {
  id: any
  name: string | null
  cargo: string | null
  hullType: HullType | null
  isPreselected: boolean
  isHot: boolean
  riskLevel: RiskLevel
  pickupType: PickupType
  tripStatus: TripStatusId
  destination: RiverLocationLite | null
  currentLocation: RiverLocationLite | null
  pickupFacility: FacilityLocation
  dropOffFacility: FacilityLocation
  towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
  loadStatus: LoadStatus
  expectedLoadStatus: LoadStatus
  type: GraphqlBargeType | null
  fleet: Fleet | null
  distanceToPickup: number | null
}

export type NominatedBargeWithReview = NominatedBarge & {
  review: { receivedTBO?: string; leftTheBargePool: boolean; otherNominations?: string[]; riskLevel?: RiskLevel }
}

export const isNominatedBargeWithReview = (barge: any): barge is NominatedBargeWithReview => 'review' in barge

export type NominatedTow = {
  efficiencyMetric: number | null
  boat: NominatableBoat
  barges: NominatedBarge[]
  stopsWithMetrics: {
    dwellMinutes: number | null
    distanceToNextStop: number | null
    travelMinutesToNextStop: number | null
    stop: RiverLocationLite
  }[]
  hasTurnboat: boolean
  tbnBarges: NominatedTbnBarge[]
}

export type NominatedTowStop = RiverLocationLite & {
  dropOffs: string[]
  pickUps: string[]
  inTowBarges: string[]
  travelMinutesToNextStop: number | null
  dwellMinutes: number | null
  distanceToNextStop: number
}

export type SavedNomination = Omit<UserBargeNomination, 'tows'> & {
  tows: Array<Omit<NominatedTow, 'barges'> & { barges: NominatedBargeWithReview[] }>
}

export type UserBargeNomination = {
  uuid: string
  recordTime: Date
  userRequest: BargeNominationRequest
  tows: NominatedTow[]
}

export const fromGqlUserBargeNomination = (
  n: Omit<UserBargeNomination, 'recordTime'> & { recordTime: string }
): UserBargeNomination => {
  const userRequest = {
    ...n.userRequest,
    bargeFilters: {
      ...n.userRequest.bargeFilters,
      expectedDepartureTime: n.userRequest.bargeFilters.expectedDepartureTime
        ? parseDate(n.userRequest.bargeFilters.expectedDepartureTime)
        : null,
      excludeNonNominatableBarges: n.userRequest.bargeFilters.excludeNonNominatableBarges,
      includeTBOs: n.userRequest.bargeFilters.includeTBOs ?? [],
    },
  }

  return {
    ...n,
    userRequest,
    recordTime: parseDate(n.recordTime),
  }
}

export const collectTowChanges = (stop: RiverLocationLite, barges: NominatedBarge[]) =>
  barges.reduce(
    (acc: { dropOffs: string[]; pickUps: string[] }, barge) => {
      if (barge.dropOffFacility && equals(stop, barge.dropOffFacility)) {
        acc.dropOffs.push(barge.id)
      }

      if (barge.pickupFacility && equals(stop, barge.pickupFacility)) {
        acc.pickUps.push(barge.id)
      }

      return acc
    },
    { dropOffs: [], pickUps: [] }
  )

export const createNominationStops = ({
  barges,
  stopsWithMetrics,
}: Pick<NominatedTow, 'barges' | 'stopsWithMetrics'>) =>
  stopsWithMetrics.reduce(
    (acc: NominatedTowStop[], { stop, distanceToNextStop, travelMinutesToNextStop, dwellMinutes }, index) => {
      const { dropOffs, pickUps } = collectTowChanges(stop, barges)

      const inTowBarges = index
        ? [...acc[index - 1].inTowBarges.filter(id => !dropOffs.includes(id)), ...pickUps]
        : pickUps

      return [
        ...acc,
        {
          ...stop,
          travelMinutesToNextStop,
          distanceToNextStop: distanceToNextStop ?? 0,
          dwellMinutes,
          dropOffs,
          pickUps,
          inTowBarges,
        },
      ]
    },
    []
  )

export function showIncludedTBOs(includeTBOs: number[]) {
  return includeTBOs.length > 0 ? includeTBOs.join(', ') : ELLIPSIS
}

export function showTransitTime(time: number) {
  const { day, hour, minute } = durationComponentsFromMinutes(time)

  return day > 0 ? `${day}d ${hour}h ${minute}m` : `${hour}h ${minute}m`
}

export function showTruncatedMilesMetric(value: number) {
  return value.toFixed(1)
}

export function showBoat(boat: string | undefined) {
  return boat || 'not selected'
}

export const towConfigurationFromUserRequest = (tc: UserBargeNomination['userRequest']['towConfiguration']) => ({
  goal: tc.goal,
  numberOfLoaded: tc.numberOfBarges - tc.numberOfEmptyBarges,
  numberOfEmpties: tc.numberOfEmptyBarges,
  numberOfStrings: tc.numberOfStrings,
  preselectedBarges: tc.preselectedBarges,
  hasTurnboat: tc.hasTurnboat,
  boat: tc.boat,
})

export function fromRequestFilters({
  lane,
  towOrigin: origin,
  towDestination: destination,
  excludeBargeIds,
  excludeNominatedBarges,
  excludeTboInfoBarges: excludeTboInfo,
  excludeBargeTypes,
  excludeTripStatuses,
  maximumDraft,
  expectedDepartureTime,
  includeTBOs,
}: BargeNominationFilters): Filter {
  const time: number | undefined = getTimeFromExpectedDeparture(expectedDepartureTime)

  return {
    lane,
    origin,
    destination,
    excludeBargeIds,
    excludeNominatedBarges,
    excludeTboInfo,
    excludeHoppers: isExcludingHoppers(excludeBargeTypes),
    excludeTanks: isExcludingTanks(excludeBargeTypes),
    excludePlacedToLoad: isExcludingPlacedToLoad(excludeTripStatuses),
    maxDraft: maximumDraft ?? undefined,
    time,
    includeTBOs
  }
}
