import { useMemo, useState } from 'react'

import { object as YupObject, string as YupString } from 'yup'

import { fromBargesQuery } from '../../../Domain/Barge'
import {  } from '../../../Domain/Hub'
import { useLaneBargesQuery } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'

import { createFormData } from './form'
import { createStageNavigation } from './navigation'
import { buildDepartureTimeDescription, buildLaneDescription, buildMaxDraftDescription, buildVesselDescription } from './selectors'
import {
  DepartureTimeFormValues,
  isDepartureTimeSelectionStage,
  isLaneSelectionStage,
  isPoolFiltersSelectionStage,
  isTowParametersSelectionStage,
  isVesselSelectionStage,
  LaneSelectionFormValues,
  NominationFormViewModel,
  NominationStage,
  PoolFiltersSelectionFormValues,
  StageData,
  VesselSelectionFormValues,
} from './types'
import { filterDestinations, filterOrigins, toBargePoolRequestParameters } from './utilities'

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

type FormState = {
  currentStage: NominationStage
  laneSelectionForm: LaneSelectionFormValues
  vesselSelectionForm: VesselSelectionFormValues
  departureTimeSelectionForm: DepartureTimeFormValues
  poolFiltersSelectionForm: any
  towParametersSelectionForm: any
}

const initialNavigationState = {
  stage: NominationStage.LaneSelection,
}

const initialFormState: FormState = {
  currentStage: NominationStage.LaneSelection,
  laneSelectionForm: {
    laneId: undefined,
    origin: undefined,
    destination: undefined,
  },
  vesselSelectionForm: {
    boatId: undefined,
    hasTurnboat: false,
  },
  departureTimeSelectionForm: {
    selectedDate: null,
    time: null,
  },
  poolFiltersSelectionForm: {
    feet: null,
    inch: null,
    nominatedBarges: false,
    tanks: false,
    openHopper: false,
    havingTboInfo: false,
    placedToLoad: false,
  },
  towParametersSelectionForm: {},
}

const useNominationFormViewModel = (): NominationFormViewModel => {
  const [stage, setStage] = useState(initialNavigationState.stage)

  // const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  // const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  // const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  // const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const [formState, setFormState] = useState<FormState>(initialFormState)

  const { lanes, hubs, boats } = useSettingsContext()

  const bargePoolParameters = toBargePoolRequestParameters(
    formState.laneSelectionForm,
    formState.departureTimeSelectionForm,
    formState.poolFiltersSelectionForm
  )

  const [{ data: bargesInPool, fetching: isFetchingBargePool }] = useLaneBargesQuery({
    variables: bargePoolParameters,
  })
  const bargePool = useMemo(
    () => ({
      lane: formState.laneSelectionForm.laneId,
      barges: bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      isFetching: isFetchingBargePool,
    }),
    [formState, bargesInPool, isFetchingBargePool]
  )

  const updateLaneSelectionForm = (values: LaneSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      laneSelectionForm: values,
    }))
  }

  const updateVesselSelectionForm = (values: VesselSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      vesselSelectionForm: values,
    }))
  }

  const updatePoolFiltersForm = (values: PoolFiltersSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      poolFiltersSelectionForm: {
        ...prev.poolFiltersSelectionForm,
        ...values,
      },
    }))
  }

  const currentStage = {
    stage,
  }

  const laneSelectionInitParams = useMemo(() => {
    const { laneId, origin } = formState.laneSelectionForm
    const origins = filterOrigins(laneId, hubs)
    const destinations = filterDestinations(laneId, origin, hubs)
    return {
      lanes,
      origins,
      destinations,
    }
  }, [formState, lanes, hubs])

  const vesselSelectionInitParams = useMemo(
    () => ({
      boats,
    }),
    [boats]
  )

  const stages: Record<NominationStage, StageData<any, any, any>> = {
    [NominationStage.LaneSelection]: {
      isSelected: isLaneSelectionStage(currentStage),
      form: createFormData(formState.laneSelectionForm, updateLaneSelectionForm),
      summary: { description: buildLaneDescription(formState.laneSelectionForm, lanes, hubs) },
      initParameters: laneSelectionInitParams,
      actions: createStageNavigation(NominationStage.LaneSelection, setStage),
    },
    [NominationStage.VesselSelection]: {
      isSelected: isVesselSelectionStage(currentStage),
      form: createFormData(formState.vesselSelectionForm, updateVesselSelectionForm),
      summary: {
        description: buildVesselDescription(
          formState.vesselSelectionForm.boatId,
          formState.vesselSelectionForm.hasTurnboat,
          boats
        ),
      },
      initParameters: vesselSelectionInitParams,
      actions: createStageNavigation(NominationStage.VesselSelection, setStage),
    },
    [NominationStage.DepartureTimeSelection]: {
      isSelected: isDepartureTimeSelectionStage(currentStage),
      form: createFormData(formState.departureTimeSelectionForm, values =>
        setFormState(prev => ({ ...prev, departureTimeSelectionForm: values }))
      ),
      summary: {
        description: buildDepartureTimeDescription(
          formState.departureTimeSelectionForm.selectedDate,
          formState.departureTimeSelectionForm.time
        ),
      },
      actions: createStageNavigation(NominationStage.DepartureTimeSelection, setStage),
    },
    [NominationStage.PoolFiltersSelection]: {
      isSelected: isPoolFiltersSelectionStage(currentStage),
      form: createFormData(formState.poolFiltersSelectionForm, updatePoolFiltersForm),
      summary: {
        description: buildMaxDraftDescription(
          formState.poolFiltersSelectionForm.feet,
          formState.poolFiltersSelectionForm.inch
        ),
      },
      actions: createStageNavigation(NominationStage.PoolFiltersSelection, setStage),
    },
    [NominationStage.TowParametersSelection]: {
      isSelected: isTowParametersSelectionStage(currentStage),
      form: createFormData(formState.towParametersSelectionForm, values =>
        setFormState(prev => ({ ...prev, towParametersSelectionForm: values }))
      ),
      summary: {},
      actions: createStageNavigation(NominationStage.TowParametersSelection, setStage),
    },
  }

  return {
    currentStage,
    stages,
    bargePool,
  }
}

export default useNominationFormViewModel
